import { LitElement, html, css } from 'lit';
import './CarouselItem';
import { logError } from "../../utils/logger";

export class Carousel extends LitElement {
  static properties = {
    items: { type: Array },
    cryptoList: { type: Array },
    visibleItemsCount: { type: Number, state: true },
    currentIndex: { type: Number, state: true },
  };

  static styles = css`
    :host {
      display: block;
      overflow: hidden;
    }
    .carousel-container {
      display: flex;
      width: 100%;
      overflow: hidden;
      justify-content: space-evenly;
      align-items: center;
      transition: transform 0.5s ease;
    }
    .button {
      cursor: pointer;
      height: fit-content;
    }
    .button > svg {
        padding: 0.75rem 0.25rem;
    }
  `;

  constructor() {
    super();
    this.items = [];
    this.cryptoList = [];
    this.currentIndex = 0;
    this.updateVisibleItemsCount();
    this.chartDataSeries = {};
  }

  async willUpdate(changedProperties) {
    if (changedProperties.has('cryptoList')) {
      const cryptoData = await this.fetchCryptoData(this.cryptoList);

      const chartDataPromises = this.cryptoList.map(fsym => this.fetchChartData(fsym));
      const chartDatas = await Promise.all(chartDataPromises);

      this.items = this.cryptoList.map((fsym, index) => ({
        image: `/images/crypto-logos/logo_${fsym}.webp`,
        name: fsym,
        current_price: cryptoData[fsym].current_price,
        price_change: cryptoData[fsym].price_change,
        chartData: chartDatas[index], // Get the resolved chart data
      }));
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', () => this.updateVisibleItemsCount());
  }

  disconnectedCallback() {
    window.removeEventListener('resize', () => this.updateVisibleItemsCount());
    super.disconnectedCallback();
  }

  updateVisibleItemsCount() {
    // Update visibleItemsCount based on screen width
    const width = window.innerWidth;
    if (width < 750) {
      this.visibleItemsCount = 1;
    } else if (width >= 750 && width < 1060) {
      this.visibleItemsCount = 2;
    } else {
      this.visibleItemsCount = 3;
    }
  }


  async fetchCryptoData(fsyms) {
    let data;
    try {
      const api_url =
        "https://min-api.cryptocompare.com/data/pricemultifull?tsyms=USD&fsyms=";
      const api_token =
        "0eecbc64e06cb199544159da3b2cb2c7d8da08dcf1a6becadfe23c4a193f2495";
      const response = await fetch(api_url + fsyms.join(','), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'authorization': api_token
        }
        // Include other options if needed
      });

      data = await response.json();
    } catch (error) {
      logError('Fetching data failed:', error);
      return null;
    }

    if (!data || !data.RAW) {
      logError('Invalid data format received:', data);
      return null;
    }

    const result = {};
    for (const key in data.RAW) {
      if (data.RAW.hasOwnProperty(key)) {
        result[key] = {
          current_price: data.RAW[key].USD.PRICE,
          price_change: data.RAW[key].USD.CHANGEPCT24HOUR
        };
      }
    }
    return result;
  }

  async fetchChartData(fsym) {
    let data;
    try {
      const api_url =
        "https://min-api.cryptocompare.com/data/v2/histoday?&tsym=USD&limit=24&fsym=";
      const api_token = "0eecbc64e06cb199544159da3b2cb2c7d8da08dcf1a6becadfe23c4a193f2495";
      const response = await fetch(api_url + fsym, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'authorization': api_token
        }
      });

      if (!response.ok) {
        logError('Network response was not ok');
      }

      const json = await response.json();

      if (!json.Data || !json.Data.Data) {
        logError('Unexpected JSON structure');
      }

      data = json.Data.Data.map((x) => x.open);
    } catch (error) {
      logError('Fetching data failed:', error);
      return null;
    }
    return data;
  }

  animateScroll(targetPosition) {
    const duration = 500; // Duration in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const fraction = Math.min(elapsedTime / duration, 1);
      const easeFraction = fraction * (2 - fraction);

      // const newScrollPosition = /* interpolate between current and target positions based on easeFraction */;
      this.renderRoot.querySelector('.carousel-container').scrollLeft = newScrollPosition;

      if (fraction < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }


  scrollLeft() {
    this.currentIndex = (this.currentIndex - this.visibleItemsCount + this.items.length) % this.items.length;
  }

  scrollRight() {
    this.currentIndex = (this.currentIndex + this.visibleItemsCount) % this.items.length;
  }



  // scrollLeft() {
  //   // Calculate targetPosition for scrolling left
  //   this.animateScroll(targetPosition);
  // }

  // scrollRight() {
  //   // Calculate targetPosition for scrolling right
  //   this.animateScroll(targetPosition);
  // }

  renderItems() {
    let itemsToShow = [];
    for (let i = 0; i < this.visibleItemsCount; i++) {
      let index = (this.currentIndex + i) % this.items.length;
      itemsToShow.push(this.items[index]);
    }
    return itemsToShow.map(item => html`
    <carousel-item .itemData=${item}></carousel-item>
    `);
  }

  render() {
    return html`
      
      <div class="carousel-container">
      <button class="button" @click="${this.scrollLeft}"><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 20 20"
      >
        <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
        ></path>
      </svg></button>
        ${this.renderItems()}
      <button class="button" @click="${this.scrollRight}"><svg
        height=24px
        width=24px
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
        ></path>
      </svg></button>
      </div>
    `;
  }
}

customElements.define('responsive-carousel', Carousel);
