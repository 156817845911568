import { LitElement, html, css } from 'lit';
import './CarouselChart';

class CarouselItem extends LitElement {
  static properties = {
    itemData: { type: Object },
  };

  static styles = css`
      .carousel-item {
      flex: 0 0 auto; /* Adjust size based on visibleItemsCount */
      height: 16rem;
      width: 16rem;
      padding: 1rem;
      margin: 0.75rem;
      border-radius: 0.75rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .carousel-item > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: rgb(var(--heading-3));
    }
    .carousel-item p {
        margin: 0;
    }
    .carousel-item > div > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .carousel-item-logo {
        height: 1.5rem;
        width: 1.5rem;
    }
    .carousel-item-price {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-top: 1rem;
    }
    .carousel-item-pct {
        font-weight: 700;
        margin-top: 1rem;
    }
  `

  constructor() {
    super();
    this.itemData = {};
  }

  formatPrice(price) {
    if (price > 1) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    } else {
      return '$' + price.toFixed(4);
    }
  }

  render() {
    if (!this.itemData) {
      return html`<div class="carousel-item">Loading...</div>`;
    }

    return html`
      <div class="carousel-item">
        <div>
            <div>
            <img src=${this.itemData.image} alt=${this.itemData.name} class="carousel-item-logo" />
            <p>${this.itemData.name}</p>
            </div>
                <p>24h</p>
        </div>
        <p class="carousel-item-price">${this.formatPrice(this.itemData.current_price)}</p>

        <p class="carousel-item-pct">${this.itemData.price_change.toFixed(2)}%</p>

        <!-- <img src=${this.itemData.chart} alt="chart" /> -->
        <carousel-chart .chartData=${this.itemData.chartData} .fsym=${this.itemData.name}></carousel-chart>
      </div>
    `;
  }
}

customElements.define('carousel-item', CarouselItem);
